<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <BlockForm :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CREATE_BLOCK } from '@/store/action-types/blocks';
import BlockForm from './BlockForm';

export default {
  name: 'BlockCreate',
  components: {
    BlockForm,
  },
  data() {
    return {
      initialValues: {
        name: '',
        siteId: '',
        type: '',
      },
    };
  },
  computed: {
    ...mapState('blocks', ['isSending']),
  },
  methods: {
    ...mapActions('blocks', [CREATE_BLOCK]),
    onSubmit(formData) {
      this.CREATE_BLOCK({ id: formData.siteId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.new_block_added'),
          });
          this.$router.push({ name: 'blocks' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.adding_record_error'),
          });
        });
    },
  },
};
</script>
