<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <div class="form-group">
          <label class="col-form-label-sm" for="type">{{ $t('form.site') }}</label>
          <multiselect
            id="type"
            v-model="formData.siteId"
            :class="{ 'is-invalid': $v.formData.siteId.$error }"
            :placeholder="$t('form.site')"
            :options="siteList"
            :show-labels="false"
            :disabled="!isCreateForm"
            track-by="value"
            label="label"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
          <small v-if="$v.formData.siteId.$error" class="form-error">{{ $t('errors.field_required') }}</small>
        </div>

        <CInput
          v-model.trim="formData.name"
          :label="$t('blocks.name')"
          size="sm"
          :class="{ 'is-invalid': $v.formData.name.$error }"
        />
        <small v-if="$v.formData.name.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <div class="form-group">
          <label class="col-form-label-sm" for="type">{{ $t('blocks.type') }}</label>
          <multiselect
            id="type"
            v-model="formData.type"
            :class="{ 'is-invalid': $v.formData.type.$error }"
            :placeholder="$t('blocks.type')"
            :options="creativeTypes"
            :show-labels="false"
            track-by="type"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
          <small v-if="$v.formData.type.$error" class="form-error">
            {{ $t('errors.field_required') }}
          </small>
        </div>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">{{ buttonText }}</CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_CREATIVE_TYPES } from '@/store/action-types/dictionary';
import { GET_SITES_LIST } from '@/store/action-types/sites';
import { filtersDefaultLimit } from '@/helpers/const';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'SiteForm',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  validations: {
    formData: {
      siteId: {
        required,
      },
      name: {
        required,
      },
      type: {
        required,
      },
    },
  },
  data() {
    return {
      formData: {
        siteId: this.initialValues.siteId,
        name: this.initialValues.name,
        type: this.initialValues.type,
      },
    };
  },
  computed: {
    ...mapGetters('dictionary', ['creativeTypes']),
    ...mapGetters('sites', ['siteList']),
    buttonText() {
      return this.isCreateForm ? this.$t('ui.create') : this.$t('ui.update');
    },
    isCreateForm() {
      return this.$route.name === 'block-create';
    },
  },
  watch: {
    creativeTypes(types) {
      const selectedType = types.find((item) => {
        return item.type === this.formData.type;
      });

      if (selectedType) {
        this.formData = { ...this.formData, type: selectedType };
      }
    },
    siteList(sites) {
      const selectedSite = sites.find((item) => {
        return Number(item.value) === Number(this.formData.siteId);
      });

      if (selectedSite) {
        this.formData = { ...this.formData, siteId: selectedSite };
      }
    },
  },
  mounted() {
    this.GET_SITES_LIST({ limit: filtersDefaultLimit });
    this.GET_CREATIVE_TYPES();
  },
  methods: {
    ...mapActions('dictionary', [GET_CREATIVE_TYPES]),
    ...mapActions('sites', [GET_SITES_LIST]),
    goBack() {
      this.$router.go(-1);
    },
    formSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const formData = {
        siteId: this.formData.siteId.value,
        name: this.formData.name,
        type: this.formData.type.type,
      };

      this.$emit('formSubmit', formData);
    },
  },
};
</script>
